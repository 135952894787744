import React, { useEffect, useState } from "react";
import "../Styles/form.css";
import logo from "../assets/StaffGuru-Logo-Final.png";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import swal from "sweetalert";
import Select from "react-select";
import Spinner from "./Spinner";
import Swal2 from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Forms = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  const [posts, setPosts] = useState([]);
  const [isLoading, SetLoading] = useState(false);
  const [select_skill, setSelectedSkill] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://recruitment.thestaffguru.com/api/positions"
        );
        setPosts(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, []);

  const [experience_year, setExperienceYear] = useState("");
  const [experience_month, setExperienceMonth] = useState("");
  const [show_current, setCtcCurrent] = useState(false);
  const [border_color, setBorder] = useState("");
  const [ctc, setCtc] = useState("");
  const [notice_period, setNoticePeriod] = useState("");
  const [expected, setExpected] = useState("");
  const [otherCalc, setOtherCalc] = useState("");
  const [desired_ctc, setDesiredCtC] = useState("");
  const [desired_ctc_Cala, setDesiredCtCCal] = useState("");
  const [resume, setResume] = useState();
  const [abilities, setAbilities] = useState("");
  const [description, setDescription] = useState("");

  const onChangeExpected = (e) => {
    setDesiredCtC(e.target.value);
    const value = parseInt(e.target.value);
    setExpected(e.target.value);
    let percentage;
    let expectedCTC;
    if (ctc.length != 0) {
      percentage = (ctc * parseInt(e.target.value)) / 100;
      expectedCTC = parseInt(ctc) + percentage;
      setTotalCtc(<span className="form-type">{expectedCTC}</span>);
      setDesiredCtCCal(expectedCTC);
      setOtherCalc("");
    } else {
      setTotalCtc("");
    }

    if (value == "20" || value == "10") {
      // setShowDesc(true);
      setTotalCtc(<p className="smiley-face">&#128578;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else if (value == "30") {
      setShowDesc(true);
      setTotalCtc(<p className="smiley-face"> &#128516;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else if (value == "40") {
      setShowDesc(true);
      setBorder("orange");
      setTotalCtc(<p className="smiley-face">&#128513;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else if (value == "50") {
      setShowDesc(true);
      setBorder("red");
      setTotalCtc(<p className="smiley-face">&#128517;</p>);
      if (ctc.length != 0) {
        setOtherCalc(<span className="form-type">{expectedCTC}</span>);
      }
    } else {
      setShowDesc(false);
    }
  };

  const onChangeExpected2 = (e) => {
    setDesiredCtC(e.target.value);
    if (e.target.value == "8k to 10k" || e.target.value == "10k to 15k") {
      setTotalCtc(<p className="smiley-face">&#128578;</p>);
      setShowDesc(false);
      setExpected(e.target.value);
    } else if (e.target.value == "15k to 20k") {
      setTotalCtc(<p className="smiley-face">&#128513;</p>);
      setShowDesc(true);
      setExpected(e.target.value);
      setBorder("purple");
    } else if (e.target.value == "20k to 25k") {
      setTotalCtc(<p className="smiley-face">&#128513;</p>);
      setShowDesc(true);
      setExpected(e.target.value);
      setBorder("orange");
    } else if (e.target.value == "25k to 30k") {
      setTotalCtc(<p className="smiley-face">&#128517;</p>);
      setShowDesc(true);
      setExpected(e.target.value);
      setBorder("red");
    } else {
      setTotalCtc("");
    }
  };

  let ExpectedCtC;
  if (experience_year == "0" && experience_month == "0") {
    ExpectedCtC = (
      <select className="pl-10" onChange={onChangeExpected2}>
        <option value="">Select Expected CTC</option>
        <option value="8k to 10k">8k to 10k</option>
        <option value="10k to 15k">10k to 15k</option>
        <option value="15k to 20k">15k to 20k</option>
        <option value="20k to 25k">20k to 25k</option>
        <option value="25k to 30k">25k to 30k</option>
      </select>
    );
  } else {
    ExpectedCtC = (
      <select className="pl-10" onChange={onChangeExpected}>
        <option value="">Select Expected CTC</option>
        <option value="10">10%</option>
        <option value="20">20%</option>
        <option value="30">30%</option>
        <option id="40%" value="40">
          40%
        </option>
        <option id="50%" value="50">
          50% or Greater than 50%
        </option>
      </select>
    );
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [positions, setPositions] = useState("");
  const [Loading, setPostLoading] = useState(false);

  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://recruitment.thestaffguru.com/api/skills"
        );
        const options = data.map((item) => ({
          label: item.skills_name,
          value: item.id,
        }));
        setSkills(options);
        setPostLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  const [total_ctc, setTotalCtc] = useState("");
  const [desc, setShowDesc] = useState(false);

  const navigate = useNavigate();

  const onMainSubmit = async (e) => {
    SetLoading(true);
    const result = select_skill.map((obj) => obj.label).join(",");

    let Calulate;
    if (desired_ctc_Cala.length != 0) {
      Calulate = desired_ctc + "-" + desired_ctc_Cala;
    } else {
      Calulate = desired_ctc;
    }
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("gender", gender);
    formData.append("skills", result);
    formData.append("position", positions);
    if (ctc.length == 0) {
      formData.append("current_ctc", 0);
    } else {
      formData.append("current_ctc", ctc);
    }
    formData.append("desired_ctc", Calulate);
    formData.append("totalexp", experience_month);
    formData.append("totalexp1", experience_year);
    formData.append("noticetime", notice_period);
    formData.append("file", resume);
    formData.append("description", description);
    formData.append("abilities", abilities);
    setPostLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: "https://recruitment.thestaffguru.com/api/staffguru",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        SetLoading(true);
        Swal2.fire({
          icon: "success",
          html: true,
          customClass: { container: "bd-position-popup" },
          title: " Your Application is Submited at Zithas Technologies! ",
          html: `<br /> We will review your resume and reach out to you accordingly. Kindly retain application ${response.data.ID}  for future reference. <br /><br/> If you have any questions, feel free to reach out to our   <br/> HR Department at <a style="color:#5c0385;font-weight:600" href="tel:(+91) 720-388-2277"> 720-388-2277 </a> or via email at <a href="mailto:hr@zithas.com" style="color:#5c0385;font-weight:600" > hr@zithas.com </a>`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "Thank You !",
          className: "bd-position-popup",
        }).then((data) => {
          navigate("/");
        });
      });
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      SetLoading(false);
    }
  };

  return (
    <div className="">
      {Loading && <Spinner />}
      <section>
        <div className="container">
          <div className="bd-service1 mb-30">
            <p className="main-logo-size text-center">
              <img className="logo-size" alt="img not appeared" src={logo} />
            </p>
          </div>
          <div className="main-bodys">
            <div className="">
              <div className="mt-3">
                <div>
                  <h4 className="text-white text-center">
                    Please Fill Out the Details & Submit the Application
                  </h4>
                </div>
                <form onSubmit={onMainSubmit}>
                  <div>
                    <div className="row mt-5 ">
                      <div className="col-md-3">
                        <div className="bd-contact-field">
                          <div>
                            <label>Name *</label>
                          </div>
                          <input
                            type="text"
                            className="form-reponsive"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="bd-contact-field">
                          <div>
                            <label>Email Address *</label>
                          </div>
                          <input
                            type="email"
                            className="form-reponsive"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=" Enter Email Address "
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="bd-contact-field">
                          <div>
                            <label>Phone Number *</label>
                          </div>
                          <input
                            type="text"
                            className="form-reponsive"
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder=" Enter Phone Number "
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="bd-contact-field">
                          <div>
                            <label> Select Gender *</label>
                          </div>
                          <select
                            aria-label=".form-select-sm example"
                            className="main-selection"
                            onChange={(e) => setGender(e.target.value)}
                            required
                          >
                            <option>Select Gender</option>
                            <option value="Male "> Male </option>
                            <option value="Female"> Female </option>
                            <option value="other"> Other </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 pt-3">
                      <div className="col-md-3">
                        <div className="bd-contact-field">
                          <div>
                            <label> Select Position *</label>
                          </div>
                          <select
                            aria-label=".form-select-sm example"
                            className="main-selection"
                            onChange={(e) => setPositions(e.target.value)}
                            required
                          >
                            <option> Select Position</option>
                            {posts.map((jobs) => {
                              return (
                                <option value={jobs.positions_name}>
                                  {jobs.positions_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="bd-contact-field">
                          <div>
                            <label>Skills *</label>
                          </div>
                          <Select
                            placeholder="Select Skills"
                            isMulti
                            options={skills}
                            onChange={(e) => {
                              setSelectedSkill(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 pt-3">
                      <div className="col-md-3">
                        <div className="bd-contact-field">
                          <div>
                            <label>Experience *</label>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <select
                                onChange={(e) => {
                                  setExperienceYear(e.target.value);
                                  if (
                                    (e.target.value == "0" &&
                                      experience_month == "0") ||
                                    (e.target.value == "0" &&
                                      experience_month.length == "0")
                                  ) {
                                    setCtcCurrent(false);
                                  } else {
                                    setCtcCurrent(true);
                                  }
                                }}
                                className="main-selection"
                                required
                              >
                                <option value=""> Year</option>
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <select
                                onChange={(e) => {
                                  setExperienceMonth(e.target.value);
                                  if (
                                    (e.target.value == "0" &&
                                      experience_year == "0") ||
                                    (e.target.value == "0" &&
                                      experience_year.length == "0")
                                  ) {
                                    setCtcCurrent(false);
                                  } else {
                                    setCtcCurrent(true);
                                  }
                                }}
                                className="main-selection"
                                required
                              >
                                <option value=""> Month</option>
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          show_current
                            ? "bd-current-open-form col-md-2 mb-25 resp-w-100"
                            : "display-none"
                        }
                      >
                        <div className="bd-contact-field">
                          <div>
                            <label>
                              Current CTC{" "}
                              <span style={{ fontSize: "10px" }}>(PM)</span>
                              <span style={{ color: "#ff4545" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Current CTC"
                              name="user_ctc"
                              onChange={(e) => setCtc(e.target.value)}
                              style={{ height: "45px" }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="bd-contact-field">
                          <div
                            className={
                              show_current ? " resp-w-100" : " resp-w-100"
                            }
                          >
                            <label>
                              Expected CTC{" "}
                              <span style={{ color: "#ff4545" }}>*</span>
                            </label>
                            <div className="d-flex align-items-center">
                              <div className="w-100">{ExpectedCtC}</div>
                              <div
                                className={
                                  otherCalc.length == 0
                                    ? "display-none"
                                    : " mx-3 "
                                }
                              >
                                {otherCalc}
                              </div>
                              <div
                                className={
                                  total_ctc.length == 0
                                    ? "display-none "
                                    : " mx-2 "
                                }
                              >
                                {total_ctc}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <div className="bd-contact-field">
                          <div
                            className={
                              desc ? " mb-25 resp-w-100" : " mb-25 resp-w-100"
                            }
                          >
                            <label>
                              Notice Period{" "}
                              <span style={{ color: "#ff4545" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Notice Period"
                              onChange={(e) => setNoticePeriod(e.target.value)}
                              name="user_period"
                              style={{ height: "45px" }}
                              required
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 pt-2">
                      <div className="col-md-12">
                        <div className="bd-contact-field">
                          <div
                            className={
                              desc
                                ? "bd-current-open-form  mb-25 resp-w-100"
                                : "bd-current-open-form  mb-25 resp-w-100"
                            }
                          >
                            <label>
                              Upload CV{" "}
                              <span style={{ color: "#ff4545" }}>*</span>
                            </label>
                            <input
                              type="file"
                              className="pt-25"
                              onChange={(e) => setResume(e.target.files[0])}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 pt-3">
                      <div className="col-md-6">
                        <div className="bd-contact-field">
                          <div className=" mb-25 resp-w-100">
                            <label>
                              Tell us how your abilities align with the position
                              ?<span style={{ color: "#ff4545" }}>*</span>
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              onChange={(e) => setAbilities(e.target.value)}
                              style={{ fontSize: "14px" }}
                              placeholder="Tell us Your Abilities"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="bd-contact-field">
                          <div
                            className={
                              desc
                                ? "bd-current-open-form resp-w-100"
                                : "display-none"
                            }
                          >
                            <label>
                              Why do you deserve such a CTC Expectation?{" "}
                              <span style={{ color: "#ff4545" }}>*</span>
                            </label>
                            <textarea
                              type="text"
                              className={`form-control ${border_color}`}
                              onChange={(e) => setDescription(e.target.value)}
                              style={{
                                fontSize: "14px",
                                borderColor: "#5c0385",
                              }}
                              placeholder="Enter Description"
                              required={desc ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-2">
                    <button className="btn-under">Apply Now</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Forms;

import React from 'react'
import "../Styles/form.css"

const Spinner = () => {
  return (
    <div>
      <section className="spinner" style={{ textAlign: "center" }}>
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </section>
    </div>
  )
}

export default Spinner
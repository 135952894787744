import { BrowserRouter as Router , Routes, Route } from 'react-router-dom';
import Instruction from './Components/Instruction';
import Forms from './Components/Forms';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Instruction/>} />
          <Route path='/jobs' element={<Forms/>} />
        </Routes>
      </Router>


    </div>
  );
}

export default App;

import React from "react";
import logo from "../assets/StaffGuru-Logo-Final.png";
import { Link } from "react-router-dom";

const Instruction = () => {
  return (
    <div>
      <section>
        <div className="container">
          <div className="bd-service1 mb-30">
            <p className="main-logo-size text-center">
              <img className="logo-size" alt="img not appeared" src={logo} />
            </p>
          </div>
          <div className="info-box">
            <div className="">
              <div className="">
                <div>
                  <h3 className="bd-form-tittle text-center">
                    Important Instructions and Guidelines !
                  </h3>
                </div>
                <div>
                  <ul className="form-info">
                    <li className="d-flex">
                      <i className="fa fa-check pr-10 line-2"></i>
                      <span>
                        <strong>Complete Contact Information </strong> : Provide
                        accurate personal details such as your full name,
                        current address, phone number, and a professional email
                        address where the employer can easily reach you.{" "}
                      </span>
                    </li>
                    <li className="d-flex">
                      <i className="fa fa-check pr-10 line-2"></i>
                      <span>
                        <strong>Mention Desired CTC </strong> :Clearly state
                        your expected salary or CTC in the designated field, if
                        required. Be realistic and research industry standards
                        to provide a competitive figure.{" "}
                      </span>
                    </li>
                    <li className="d-flex">
                      <i className="fa fa-check pr-10 line-2"></i>
                      <span>
                        <strong>Specify Relevant Skills</strong> Highlight your
                        relevant skills, that match the job requirements.
                        Emphasise how your abilities align with the position you
                        are applying for.{" "}
                      </span>
                    </li>
                    <li className="d-flex">
                      <i className="fa fa-check pr-10 line-2"></i>
                      <span>
                        <strong> Application ID </strong> : Safeguard Your
                        Application ID for Future Reference{" "}
                      </span>
                    </li>
                    <li className="d-flex">
                      <i className="fa fa-check pr-10 line-2"></i>
                      <span>
                        <strong>Monthly Notation </strong> :Please Provide CTC,
                        Expected CTC, and Notice Period in Months, Not Days{" "}
                      </span>
                    </li>
                    <li className="d-flex">
                      <i className="fa fa-check pr-10 line-2"></i>
                      <span>
                        <strong> Post-Application Inquiries </strong> : For any
                        queries, kindly contact the HR Department via phone at{" "}
                        <a
                          className="text-red"
                          href="callTo:(+91) 720-388-2277"
                        >
                          (+91) 720-388-2277
                        </a>{" "}
                        or email at{" "}
                        <a className="text-red" href="mailto:hr@zithas.com">
                          hr@zithas.com.
                        </a>{" "}
                      </span>
                    </li>
                    <div className="text-right">
                      <Link to="/jobs">
                        <button className="btn-under">
                          I Understand <i className="fa fa-thumbs-up pl-10"></i>
                        </button>
                      </Link>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Instruction;
